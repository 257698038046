import _ from "lodash";
import { mapActions, mapGetters } from 'vuex';
import * as getterTypes from '@/store/modules/novaPochta/types/getters';
import * as actionTypes from '@/store/modules/novaPochta/types/actions';
import notifications from './notifications';

export default {
  mixins: [notifications],
  data: () => ({
    activeCities: [],
    activeWarehouse: [],
    regionLoading: false,
    cityPagination:{
      page: 1,
      perPage: 10,
      searchQuery:"",
      isLoadDisabled:false,
      ref:""
    },
    warehousePagination:{
      page: 1,
      perPage: 10,
      searchQuery:"",
      isLoadDisabled:false,
      cityRef:'',
    },
  }),
  methods: {
    ...mapActions('novaPochta', {
      fetchNovaPochtaCities: actionTypes.FETCH_CITIES,
      fetchWarehouses: actionTypes.FETCH_WAREHOUSES,
      fetchWarehouseByRef: actionTypes.FETCH_WAREHOUSE_BY_REF,
      fetchNPCitiesByRegion: actionTypes.FETCH_CITIES_BY_REGION,
    }),
    clearWarehouse() {
      if (this.delivery) {
        this.delivery.warehouse = '';
      }
      if (this.address) {
        this.address.warehouse = '';
      }
    },
    updateNameAddress() {
      if (this.delivery.name_city) {
        //
        const city = this.cities.find((cityItem) => cityItem.Ref === this.inputDelivery.city);
        this.delivery.name_city = city?.Description || this.item.delivery_address_details.name_city;
        const warehouse = this.warehouses.find((cityItem) => cityItem.Ref === this.inputDelivery.warehouse.Ref);
        // this.inputDelivery.warehouse_id = warehouse?.Number || 0;
        this.inputDelivery.name_warehouse = warehouse?.Description || '';
      }
    },
   nextPageWarehouse: _.debounce(async function(){
      this.warehousePagination.page++;
      await this.fetchWarehouses(this.warehousePagination)
      this.activeWarehouse.push(...this.warehouses)
      this.warehousePagination.isLoadDisabled = this.warehouses.length > this.warehousePagination.perPage;
   },700),

   onSearchWarehouse: _.debounce(async function(data){
    this.warehousePagination.page = 1;
    this.warehousePagination.cityRef = this.inputDelivery.city;
    this.warehousePagination.searchQuery = data;
     if (this.uuid_board) {
       this.warehousePagination.uuid_board = this.uuid_board;
     }
    await this.fetchWarehouses(this.warehousePagination)
    if(this.warehouse){
      this.activeWarehouse = this.warehouses
    }
   },700),

    async onSelectCity(cityRef,info, uuidOrder = '') {
      try {

        if(info == 'delivery'){
          const item = this.cities.find((cityItem) => cityItem.Ref === this.inputDelivery.city);
          this.inputDelivery.name_city = item?.Description || '';
          //
        }
        if(info == 'createAdress'){
          const item = this.cities.find((cityItem) => cityItem.Ref === this.address.city);
          this.address.name_city = item?.Description || item?.name_city || '';
        }
        if(info == 'editAdress'){
          const item = this.cities.find((cityItem) => cityItem.Ref === this.inputAddress.uuid_ref_city);
          this.inputAddress.name_city = item?.Description;
        }

        this.warehousePagination.cityRef = cityRef

        if (uuidOrder) {
          this.warehousePagination.uuid_order = uuidOrder
        }
        if (this.uuid_board) {
          this.warehousePagination.uuid_board = this.uuid_board;
        }
        await this.fetchWarehouses(this.warehousePagination);
        this.activeWarehouse = this.warehouses
        this.clearWarehouse();
      } catch (e) {
        console.log(e)
      }
    },
    onSelectWarehouse: _.debounce(async function(info){
      try {
      if(info == 'delivery'){
        const item = this.activeWarehouse.find((cityItem) => cityItem.Ref === this.inputDelivery.warehouse.Ref);

        this.inputDelivery.name_warehouse = item?.Description;
        // this.delivery.warehouse_id = item?.Number || 0;
      }
      if(info == 'createAdress'){
        const item = this.activeWarehouse.find((cityItem) => cityItem.Ref === this.inputAddress.uuid_ref_warehouse.Ref);
        this.inputAddress.name_warehouse = item?.Description;
      }
        if (info == "editAdress") {
          if (!this.inputAddress.uuid_ref_warehouse.Ref) {
            this.warehousePagination = {
              page: 1,
              perPage: 10,
              searchQuery: "",
              isLoadDisabled: false,
              cityRef: ""
            };
            await this.onChangeCity(this.inputAddress.uuid_ref_city.Ref)
          }
          const item = this.activeWarehouse.find(
            cityItem => cityItem?.Ref === this.inputAddress.uuid_ref_warehouse.Ref
          );
          this.inputAddress.name_warehouse = item?.Description;
        }

      } catch (e) {
        console.log(e)
      }
    },100),
    async onSelectRegion(regionRef) {
      try {
        this.regionLoading = true;
        await this.fetchNPCitiesByRegion(regionRef);
        // this.clearWarehouse();
        this.activeCities = this.cities.filter((city) => city.AreaRef === regionRef);
        this.regionLoading = false;
      } catch (e) {
        this.regionLoading = false;
        console.log(e)
      }
    },
  },
  computed: {
    ...mapGetters('novaPochta', {
      cities: getterTypes.GET_CITIES,
      warehouses: getterTypes.GET_WAREHOUSES,
    }),
  },
};
